.Isanta {
  z-index: 990;
  width: 75%;
  height: 100%;
  max-height: 220px;

  background: url(../../assets/images/Desktop/Robot.png) center no-repeat;
  background-size: contain;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 45px;

  margin-left: auto;
  margin-right: auto;

  animation: santaBounceMobile 2s infinite;
  animation-timing-function: ease-in-out;

  @media (min-width: 592px) {
    width: 100%;
    bottom: 95px;
    animation: santaBounceTablet 2s infinite;
  }

  @media (min-width: 784px) {
    bottom: 145px;
    animation: santaBounceDesktop 2s infinite;
  }

  @keyframes santaBounceMobile {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(7px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes santaBounceTablet {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes santaBounceDesktop {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(15px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}
