.Present {
    max-width: 280px;
    height: 350px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    
    opacity: 0;
    transition: opacity 1s;

    z-index: -1;
    
    @media (min-width : 598px) {
        height: 480px;
        width: 100%;
        max-width: 455px;
    }
    
    &.isShown {
        transition: 2s all;
        transition: opacity 1s;
        position: absolute;
        top: 0;

        opacity: 1;

        z-index: 401;
    }

    .Present__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 18px;

        background: white;

        border-radius: 16px;

        position: relative;

        @media (min-width : 598px) {
            padding: 32px;
        }
        

        .wrapper__present-image {
            max-width: 190px;
            width: 100%;
            height: 160px;
            display: inline-block;
            align-self: center;

            @media (min-width : 598px) {
                width: 100%;
                max-width: 300px;
                height: 290px;
            }
        }

        .wrapper__santa-icon {
            width: 58px;
            height: 48px;

            position: absolute;
            top: -32px;
            left: 0;
            right: 0;
            margin: auto;
        }

        .wrapper__text {

        }
    }

    .Present__wrong {
        color: white;
        text-align: center;
        margin-top: -8px;
        
        span {
            text-decoration: underline;
            font-weight: bold;
            cursor: pointer;
        }

        @media (min-width : 598px) {
            margin-top: 30px;
        }
    }

    .Button {
        position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          bottom: -60px;
          z-index: 401;
    
        button {
            width: 110px;
            height: 45px;
            
            background: #E2345E;
            
            border-radius: 100px;
            
            color: white;
            
            text-align: center;
            text-decoration: none;
            border: none;
            
            display: inline-block;
            
            font-size: 16px;

            position: absolute;
            left: 0;
            right: 0;
          

            margin-left: auto;
            margin-right: auto;

            @media (min-width : 598px) {
                height: 80px;
                width: 150px;
                max-width: 455px;
            }
        }
      }
}