body {
  height: 100%;
}

#root {
  height: 100%;
}

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 0px;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
$screen-sm: 592px;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 784px;
// Large screen / wide desktop
$screen-lg: 1200px;

$screen-lg-max: 1440px;

.App {
  background: url(assets/images/Mobile/Background.svg),
    linear-gradient(180deg, #2f006a 10%, #93005f 40%, #ff7293 65%);
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;

  position: fixed;

  @media (min-width: $screen-md) {
    background: url(assets/images/Mobile/Background.svg),
      linear-gradient(180deg, #2f006a 10%, #93005f 40%, #ff7293 65%);
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  @media (min-width: $screen-lg) {
    background: url(assets/images/Desktop/BG-Desktop.svg),
      linear-gradient(180deg, #2f006a 10%, #93005f 40%, #ff7293 65%);
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 268px;
    height: 268px;
    right: 0px;
    top: 0px;

    background: radial-gradient(
      189.5px at 100% 0%,
      #180035 0%,
      rgba(24, 0, 53, 0) 100%
    );
    opacity: 0.6;
  }

  .Moon {
    background: url(assets/images/Mobile/Moon.svg);

    z-index: -1;

    width: 176px;
    height: 176px;

    position: absolute;
    right: 0;
    top: 0px;

    @media (min-width: $screen-md) {
      background: url(assets/images/Tablet/Moon.svg);
      width: 348px;
      height: 320px;
    }

    @media (min-width: $screen-lg) {
      background: url(assets/images/Desktop/Moon.svg);
      width: 352px;
      height: 352px;

      right: 160px;
      top: 0px;
    }
  }

  .BushRight {
    z-index: 1;

    width: 202px;
    height: 119px;

    position: absolute;
    right: 0;
    bottom: 0px;

    background: url(assets/images/Mobile/Bush-R.svg);

    @media (min-width: $screen-sm) {
      background: url(assets/images/Tablet/Bush-R.svg);
      width: 415px;
      height: 182px;
    }

    @media (min-width: $screen-lg) {
      background: url(assets/images/Desktop/Bush-R.svg);
      width: 662px;
      height: 275px;
    }
  }

  .TreeLeft {
    width: 125px;
    height: 428px;

    position: absolute;
    left: 0px;
    bottom: 100px;

    background: url(assets/images/Mobile/Tree-L.svg);

    @media (min-width: $screen-sm) {
      background: url(assets/images/Tablet/Tree-L.svg);

      width: 228px;
      height: 799px;
    }

    @media (min-width: $screen-md) {
      background: url(assets/images/Desktop/Tree-L.svg);

      width: 264px;
      height: 928px;
    }
  }

  .TreeRight {
    width: 116px;
    height: 426px;

    position: absolute;
    right: 0px;
    bottom: 100px;

    background: url(assets/images/Mobile/Tree-R.svg);

    @media (min-width: $screen-sm) {
      background: url(assets/images/Tablet/Tree-R.svg);

      width: 218px;
      height: 804px;
    }

    @media (min-width: $screen-md) {
      background: url(assets/images/Desktop/Tree-R.svg);

      width: 252px;
      height: 931px;

      right: 0px;
      bottom: 160px;
    }
  }

  .Logo {
    z-index: 2;

    display: none;

    position: absolute;
    right: 40px;
    top: 25px;

    color: white;
    font-weight: bold;
    text-decoration: none;

    @media (min-width: $screen-sm) {
      display: flex;
      flex-direction: column;

      background: rgba(24, 0, 42, 0.04);
    }

    .tp-logo {
      width: 78px;
      height: 32px;
      display: inline-block;

      background: url(assets/images/ThisPlace-Logo.svg);

      align-self: flex-end;
    }
    .christmas-tree {
      display: inline-block;
    }
  }
  .pageBody-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(24, 0, 42, 0.9);
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    z-index: -1;

    &.isShown {
      opacity: 1;
      z-index: 401;
    }
  }
}
