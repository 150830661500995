.IsantaShadow {
  z-index: 990;
  width: 40%;
  height: 10px;

  background: url(../../assets/images/Desktop/Shadow.svg) center no-repeat;
  background-size: contain;
  background-position: center bottom;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 65px;

  margin-left: auto;
  margin-right: auto;

  animation: shadowshrinkingMobile 2s infinite;
  animation-timing-function: ease-in-out;

  @media (min-width: 592px) {
    height: 12px;
    bottom: 65px;
    animation: shadowshrinkingTablet 2s infinite;
  }

  @media (min-width: 784px) {
    height: 15px;
    bottom: 100px;
    animation: shadowshrinkingDesktop 2s infinite;
  }

  @keyframes shadowshrinkingMobile {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scaleX(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes shadowshrinkingTablet {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scaleX(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes shadowshrinkingDesktop {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scaleX(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
}
